<template>
  <b-modal
    ref="add-media-modal-1"
    centered
    hide-footer
    hide-header
  >
    <div class="add-media-modal d-flex justify-content-center align-content-center align-items-center">
      <b-row style="display: block ">
        <div class="closediv">
          <b-button
            variant="transparent"
            class="closebtn"
            @click="hide()"
          >
            <feather-icon
              icon="XIcon"
              class="cursor-pointer"
            />
          </b-button>
        </div>
        <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center m-2 mb-0">
          <p class="font-weight-bolder heading1">
            Add Media
          </p>
        </div>
        <div class="d-inline-block m-1 mt-0 d-flex justify-content-center align-content-center align-items-center">
          <div
            id="form"
            class="form pb-2 w-100 "
          >
            <validation-observer ref="addMediaForm">
              <b-form
                class="p-0"
                @submit.prevent
              >
                <!-- Media name -->
                <div class="mb-1">
                  <b-form-group
                    text-bolder
                    label="Name"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="mediaName"
                      rules="required"
                    >
                      <b-form-input
                        id="input-1"
                        v-model="fileData.name"
                        placeholder="Please enter the file's name here"
                        trim
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <!-- Media Type -->
                <div>
                  <b-form-group
                    text-bolder
                    label="Select Type"
                    class="label"
                  >
                    <validation-provider
                      v-slot="{ errors }"
                      name="Select Type"
                      rules="required"
                    >
                      <v-select
                        v-model="selectedTitle"
                        :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                        label="title"
                        :options="option"
                        class="label"
                        :clearable="false"
                        @input="selectChangeHandler"
                      />
                      <small class="text-danger ">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>

                <!-- Video uploading -->
                <div v-if="selectedTitle && selectedTitle.title && selectedTitle.title === 'Video'">
                  <div class="d-inline-block d-flex justify-content-center align-content-center align-items-center position-relative mt-1">
                    <div
                      class="upload-video-image-div"
                      @dragover.prevent
                      @drop.prevent
                    >
                      <div @drop="dragFileVideo">
                        <span class=" d-flex justify-content-center align-content-center">
                          <b-img
                            :src="fileData.file? require('@/assets/images/simiicons/Video(1).svg') : require('@/assets/images/simiicons/Video(2).svg')"
                            alt="data.json"
                          />
                        </span>
                        <div
                          v-if="!fileSrc.videoSrc"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="'Drag & drop Video here or'"
                          class="text-truncate"
                        >
                          <small
                            class="font-small-1 text-truncate"
                          >Drag & drop Video here or</small>
                        </div>
                        <div
                          v-else
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="fileData.file ? fileData.file.name : null"
                          class="text-truncate"
                        >
                          <strong
                            class=" font-small-1"
                          >{{ fileData.file?fileData.file.name : '' }}</strong>
                        </div>
                        <b-form-group
                          text-bolder
                          class="label"
                        >
                          <validation-provider
                            name="video"
                          >
                            <b-form-file
                              id="video"
                              v-model="fileData.file"
                              :hidden="true"
                              plain
                              class="pl-12"
                              accept="video/*"
                              @change="uploadVideo($event)"
                            />
                            <span v-if="uploadPercentage > 0 && uploadPercentage < 100 ">
                              <b-progress
                                v-model="uploadPercentage"
                                max="100"
                              />
                            </span>
                            <b-link
                              v-if="fileSrc.videoSrc"
                              class="d-flex justify-content-center align-items-center font-small-2"
                              @click="removeVideo"
                            >
                              Remove
                            </b-link>
                            <b-link
                              v-else
                              class="d-flex justify-content-center align-items-center font-small-2"
                              @click="chooseVideoFiles"
                            >
                              Browse
                            </b-link>
                          </validation-provider>
                        </b-form-group>
                      </div>
                    </div>
                    <div
                      class="upload-thumbnail-div"
                      @dragover.prevent
                      @drop.prevent
                    >
                      <div
                        @drop="dragFileThumbnail"
                      >
                        <span class=" d-flex justify-content-center align-content-center">
                          <b-img
                            :src="fileSrc.thumbnailSrc ? fileSrc.thumbnailSrc :require('@/assets/images/simiicons/thumbnail.svg')"
                            alt="data.json"
                            :class="fileSrc.thumbnailSrc ? 'imageSrc': null"
                          />
                        </span>
                        <div
                          v-if="!fileSrc.thumbnailSrc"
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="'Drag & drop Thumbnail here or'"
                          class="text-truncate"
                        >
                          <small
                            class="font-small-1 text-truncate"
                          >Drag & drop Thumbnail here or</small>
                        </div>
                        <div
                          v-else
                          v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                          v-b-tooltip.hover.top="fileData.thumbnail ? fileData.thumbnail.name : null"
                          class="text-truncate d-flex justify-content-center align-items-center"
                        >
                          <strong
                            class="font-small-1"
                          >{{ fileData.thumbnail? fileData.thumbnail.name: '' }}</strong>
                        </div>
                        <b-form-group
                          text-bolder
                          class="label"
                        >
                          <b-form-file
                            id="thumbnailRef"
                            v-model="fileData.thumbnail"
                            accept=".jpg, .png, .gif"
                            :hidden="true"
                            plain
                            @change="uploadThumbnail($event)"
                          />
                          <span v-if="uploadPercentage > 0 && uploadPercentage < 100 && fileData.thumbnail">
                            <b-progress
                              v-model="uploadPercentage"
                              max="100"
                            />
                          </span>
                          <b-link
                            v-if="fileSrc.thumbnailSrc"
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="removeThumbnail"
                          >
                            Remove
                          </b-link>
                          <b-link
                            v-else
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="thumbnailClick"
                          >
                            Browse
                          </b-link>
                        </b-form-group>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- upload Image-->
                <div
                  v-if="selectedTitle && selectedTitle.title && selectedTitle.title === 'Image'"
                  class="d-flex justify-content-center align-items-center imageDiv"
                >
                  <div
                    class="upload-image-div"
                    @dragover.prevent
                    @drop.prevent
                  >
                    <div
                      class="pt-1"
                      @drop="dragFileImage"
                    >
                      <span class=" d-flex justify-content-center align-content-center">
                        <b-img
                          :src="fileSrc.imageSrc ? fileSrc.imageSrc : require('@/assets/images/simiicons/Image(2).svg')"
                          alt="data.json"
                          :class="fileSrc.imageSrc ? 'imageSrc': null"
                        />
                      </span>
                      <small
                        v-if="!fileSrc.imageSrc"
                        class="text-nowrap font-small-1 d-flex justify-content-center align-items-center"
                      >Drag & drop Images here or</small>
                      <div
                        v-else
                        v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                        v-b-tooltip.hover.top="fileData.file ? fileData.file.name: null"
                        class="text-truncate"
                      >
                        <strong
                          class="font-small-1 d-flex justify-content-center align-items-center"
                        >{{ fileData.file ? fileData.file.name: '' }}</strong>
                      </div>
                      <b-form-group
                        text-bolder
                        class="label"
                      >
                        <validation-provider
                          name="image"
                        >
                          <b-form-file
                            id="image"
                            v-model="fileData.file"
                            :hidden="true"
                            plain
                            class="pl-12"
                            accept="image/*"
                            @change="uploadImage($event)"
                          />
                          <span v-if="uploadPercentage > 0 && uploadPercentage < 100">
                            <b-progress
                              v-model="uploadPercentage"
                              max="100"
                            />
                          </span>
                          <b-link
                            v-if="fileSrc.imageSrc"
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="removeImage"
                          >
                            Remove
                          </b-link>
                          <b-link
                            v-else
                            class="d-flex justify-content-center align-items-center font-small-2"
                            @click="chooseImage"
                          >
                            Browse
                          </b-link>
                        </validation-provider>
                      </b-form-group>
                    </div>
                  </div>
                </div>
                <div class="small px-2">
                  {{ errorMsg ? errorMsg: null }}
                </div>
                <div class="block mb-2 mx-2">
                  <b-button
                    type="submit"
                    class="button mt-1 w-100"
                    variant="primary"
                    block
                    :disabled="spinner"
                    @click="addMedia"
                  >
                    <div
                      v-if="spinner"
                      class="spinner"
                    >
                      <b-spinner
                        small
                      />
                    </div>
                    Save
                  </b-button>
                </div>
              </b-form>
            </validation-observer>
          </div>
        </div>
      </b-row>
    </div>
  </b-modal>
</template>

<script>
import vSelect from 'vue-select'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import Ripple from 'vue-ripple-directive'
import { required } from '@validations'
import {
  BRow,
  BFormInput,
  BButton,
  BForm,
  BFormGroup,
  BFormFile,
  BImg,
  BLink,
  BSpinner,
  BProgress,
  VBTooltip,
} from 'bootstrap-vue'
import { getFormDataFromJSON, showErrorMessage, showToast } from '@/common/global/functions'

export default {
  components: {
    BRow,
    BFormInput,
    BButton,
    BForm,
    BFormFile,
    BFormGroup,
    BImg,
    BLink,
    BSpinner,
    BProgress,
    vSelect,
    // validations
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  data() {
    return {
      typesOfImage: ['image/jpeg', 'image/png', 'image/jpg', 'image/gif'],
      typesOfVideo: ['video/mp4', 'video/3gpp', 'video/mov', 'video/mkv', 'video/x-matroska', 'video/quicktime'],
      fileData: {
        name: '',
        file: null,
        thumbnail: null,
      },
      fileSrc: {
        imageSrc: null,
        thumbnailSrc: null,
        videoSrc: null,
      },
      errorMsg: null,
      uploadPercentage: 0,
      spinner: false,
      videoData: '',
      selectedTitle: { title: 'Video' },
      option: [{ title: 'Video' }, { title: 'Image' }],
      // validation
      required,
    }
  },
  methods: {
    async addMedia() {
      this.$refs.addMediaForm.validate().then(async success => {
        if (success) {
          if (this.fileSrc.videoSrc === null && this.fileSrc.imageSrc === null) {
            this.errorMsg = 'Please upload Image or Video to add media'
            this.fileData.file = null
          } else if (this.fileSrc.videoSrc !== null && this.fileSrc.thumbnailSrc === null) {
            this.errorMsg = 'Please upload thumbnail to add media'
          } else {
            this.spinner = true
            await this.addMediaStorage()
            this.spinner = false
            await this.hide()
            await this.$emit('getAllMediaStorageOfUser')
            this.fileData = {}
          }
        }
      })
    },
    async  addMediaStorage() {
      this.uploadPercentage = 0
      try {
        await this.$axios
          .post('media',
            getFormDataFromJSON(this.fileData), {
              onUploadProgress: event => {
                this.uploadPercentage = Math.round((event.loaded * 100) / event.total)
              },
            })
        showToast('Add Media', 'Media has been added successfully!')
        this.spinner = false
      } catch ({
        response: {
          data: {
            statusCode, message,
          },
        },
      }) {
        if (showErrorMessage(statusCode)) {
          showToast('Media Storage', message.toString(), 'danger')
          this.loading = false
        }
      } finally {
        this.loading = false
      }
    },
    show() {
      this.uploadPercentage = 0
      this.fileData = {}
      this.errorMsg = null
      this.fileSrc.imageSrc = null
      this.fileSrc.thumbnailSrc = null
      this.fileSrc.videoSrc = null
      this.$refs['add-media-modal-1'].show()
    },
    hide() {
      this.$refs['add-media-modal-1'].hide()
    },
    selectChangeHandler() {
      this.errorMsg = null
      this.videoData = null
      this.fileData.file = null
      this.fileData.thumbnail = null
      this.removeImage()
      this.removeVideo()
      this.removeThumbnail()
    },
    chooseVideoFiles() {
      document.getElementById('video').click()
    },
    chooseImage() {
      document.getElementById('image').click()
    },
    thumbnailClick() {
      document.getElementById('thumbnailRef').click()
    },
    removeThumbnail() {
      this.fileSrc.thumbnailSrc = null
      this.fileData.thumbnail = null
      this.errorMsg = null
    },
    removeImage() {
      this.fileSrc.imageSrc = null
      this.fileData.file = null
      this.errorMsg = null
    },
    removeVideo() {
      this.fileSrc.videoSrc = null
      this.fileData.file = null
      this.errorMsg = null
    },
    uploadThumbnail(event) {
      this.errorMsg = null
      const obj = event.target.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.thumbnailSrc = URL.createObjectURL(obj)
          this.fileData.thumbnail = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.thumbnail = null
        }
      } else {
        this.errorMsg = 'Please upload Image for Thumbnail!'
        this.fileData.thumbnail = null
        this.fileSrc.thumbnailSrc = null
      }
    },
    dragFileThumbnail(e) {
      this.errorMsg = null
      const obj = e.dataTransfer.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.thumbnailSrc = URL.createObjectURL(obj)
          this.fileData.thumbnail = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.thumbnail = null
        }
      } else {
        this.errorMsg = 'Please upload Image for Thumbnail!'
        this.fileData.thumbnail = null
        this.fileSrc.thumbnailSrc = null
      }
    },
    dragFileImage(e) {
      this.errorMsg = null
      const obj = e.dataTransfer.files[0]
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.imageSrc = URL.createObjectURL(obj)
          this.fileData.file = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.file = null
        }
      } else {
        this.errorMsg = 'Please upload Image having type jpeg, png,jpg!'
        this.fileData.file = null
        this.fileSrc.imageSrc = null
      }
    },
    uploadImage(event) {
      this.errorMsg = null
      this.randomKey = Math.random()
      const [obj] = event.target.files
      const { size, type } = obj
      if (this.typesOfImage.includes(type)) {
        if (size < '26214400 ') {
          this.fileSrc.imageSrc = URL.createObjectURL(obj)
          this.fileData.file = obj
        } else {
          this.errorMsg = 'Max size of file should be 25MB'
          this.fileData.file = null
        }
      } else {
        this.errorMsg = 'Please upload Image having type jpeg, png, jpg!'
        this.fileData.file = null
        this.fileSrc.imageSrc = null
      }
    },
    dragFileVideo(e) {
      this.errorMsg = null
      const [obj] = e.dataTransfer.files
      const { size, type } = obj
      const video = document.createElement('video')
      let duration = ''
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        duration = video.duration
        if (this.typesOfVideo.includes(type)) {
          if (size < '104857600 ' && duration >= 5) {
            this.fileSrc.videoSrc = URL.createObjectURL(obj)
            this.fileData.file = obj
            this.errorMsg = null
          } else {
            this.errorMsg = 'Max size of file should be 100MB'
            this.fileData.file = null
          }
        } else {
          this.errorMsg = 'Please upload Video having type mp4, 3gpp, mov,mkv,x-matroska,quicktime!'
          this.fileData.file = null
          this.fileSrc.videoSrc = null
        }
      }
      video.src = URL.createObjectURL(obj)
    },
    async uploadVideo(event) {
      const [obj] = event.target.files
      const { size, type } = obj
      const video = document.createElement('video')
      let duration = ''
      video.onloadedmetadata = () => {
        window.URL.revokeObjectURL(video.src)
        duration = video.duration
        if (this.typesOfVideo.includes(type)) {
          if (size < 104857600 && duration >= 5) {
            this.fileSrc.videoSrc = URL.createObjectURL(obj)
            this.fileData.file = obj
            this.errorMsg = null
          } else {
            this.errorMsg = 'Max size of file should be 100MB or min 5sec!'
            this.fileData.file = null
          }
        } else {
          this.errorMsg = 'Please upload Video having type mp4, 3gpp, mov,mkv,x-matroska,quicktime!'
          this.fileData.file = null
          this.fileSrc.videoSrc = null
        }
      }
      video.src = URL.createObjectURL(obj)
    },
  },
}
</script>
<!--<style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>-->
<style lang="scss">
.add-media-modal {
  .addprofile {
    justify-content: center;
    align-items: center;
    margin-top: 40px;
  }
  .title {
    /*width: 100%;*/
    height: 44px;
    background-color: #f8f8f8;
    justify-content: space-between;
    align-items: center;
    display: flex;
    padding-left: 12px;
  }

  .form {
    padding-inline: 10px;
    min-width: 320px !important;
    max-width: 380px !important;
    @media (max-width: 640px) {
      min-width: 320px !important;
      max-width: 320px !important;
    }
    @media (max-width: 560px) {
      min-width: 280px !important;
      max-width: 280px !important;
    }
  }

  .small {
    font-size: 12px;
    line-height: 22px;
    color: #cf0000;
    font-weight: 500;
    font-family: "Montserrat";
    text-align: center;
    margin-top: 10px;
  }

  .choose {
    margin: auto;
    margin-top: 15px;
    display: flex;
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }

  .choose:focus {
    background-color: #e0dee1 !important;
    color: #6e6b7b !important;
    border-color: #e0dee1 !important;
  }

  .select {
    min-width: 380px;
    height: 38px;
    border-radius: 6px;
    background-color: #ffffff;
    border: 1px solid #d8d6de;
    margin: 5px
  }

  .head {
    font-size: 14px;
    line-height: 24px;
    color: #1f58b5;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .label {
    font-size: 14px;
    color: #5e5873;
    font-weight: 600;
    font-family: "Montserrat";
  }

  .thumbnaildiv {
    width: 20px;
    height: auto;
  }

  .thumbImg {
    width: 60px;
    height: auto;
  }

  .upload-video-image-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding: 14px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
    @media (max-width: 640px) {
      max-width: 140px !important;
      min-width: 140px  !important;
    }
    @media (max-width: 600px) {
      max-width: 120px !important;
      min-width: 120px  !important;
    }
  }
  .upload-image-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding: 14px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
  }

  .loadedData {
    border: solid #8080803b;
    border-radius: 2px;
  }

  .mediaImage {
    width: 40px;
    height: 40px;
  }

  .upload-thumbnail-div {
    border: dotted #8080803b;
    border-radius: 10px;
    margin: 5px;
    padding-left: 2px;
    padding-right: 2px;
    padding-top: 26px;
    max-width: 170px;
    min-width: 170px;
    min-height: 134px;
    max-height: 160px;
    @media (max-width: 640px) {
      max-width: 140px !important;
      min-width: 140px  !important;
    }
    @media (max-width: 600px) {
      max-width: 120px !important;
      min-width: 120px  !important;
    }
  }

  .imageSrc {
    width: 25px;
    height: 25px;
  }
  .imageDiv {
    min-width: 360px;
    @media (max-width: 640px) {
      min-width: 0px !important;
      max-width: 340px !important;
    }
  }
}

</style>
