<template>
  <div
    class="custom-pagination d-flex justify-content-between align-items-center"
  >
    <div>
      <label className="text-nowrap font-weight-bold">
        Showing
        <b>{{ total > ((page - 1) * perPage + 1) ? ((page - 1) * perPage + 1) : total }}</b>
        to
        <b>{{ ((page - 1) * perPage + perPage) > total ? total : (page - 1) * perPage + perPage }}</b> of
        <b>
          {{ total }}
        </b>
        Records
      </label>
      <v-select
        :value="perPage"
        dir="ltr"
        :options="pageOptions"
        :clearable="false"
        label="10"
        :searchable="false"
        class="per-page-selector d-inline-block mx-50"
        @input="$emit('perPageChanged', $event)"
      />
    </div>
    <div>
      <b-pagination
        :value="page"
        :total-rows="total"
        :per-page="perPage"
        hide-goto-end-buttons
        class="mb-0 mt-1 mt-sm-0"
        size="sm"
        pills
        @change="$emit('pageChanged', $event)"
      />
    </div>
  </div>
</template>

<script>
import { BPagination } from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'CustomPagination',
  components: {
    BPagination,
    vSelect,
  },
  props: {
    total: {
      type: Number,
      required: true,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 10,
      required: true,
    },
    page: {
      type: Number,
      required: true,
      default: 1,
    },
    pageOptions: {
      type: Array,
      default: () => [5, 10, 20, 50],
    },
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px !important;
}
</style>
